import React from 'react'
import { graphql } from 'gatsby'

import { parseText } from '../classes/ParseText'
import LayoutStatic from '../components/LayoutStatic'
import ShareList from '../components/ShareList'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import innerText from 'react-innertext'

import Moment from 'react-moment'
import 'moment-timezone'
Moment.globalTimezone = 'Asia/Tokyo'
Moment.globalFormat = 'YYYY.M.D'

class Post extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const { previous, next, metadata, categorySlug } = this.props.pageContext

    const locale = this.props.intl.locale

    const isJP = this.props.intl.locale === 'ja'

    const pageTitle = isJP ? post.frontmatter.title.ja : post.frontmatter.title.en

    const isFaq = categorySlug === 'faq'
    const pagerClassName = !previous && next ? 'prevnext nextPostOnly' : 'prevnext'

    const category = this.props.data.site.siteMetadata.postCategories
    const categoryForLocale = category[post.frontmatter.category][locale]
    const categoryCommon = category[post.frontmatter.category].common

    const contentHtml = parseText(post.html, this.props.intl)
    let excerpt = innerText(contentHtml).trim()
    if (excerpt.length > 60) {
      excerpt = excerpt.substr(0, 60) + "..."
    }

    return (
      <LayoutStatic {...this.props} title={pageTitle} description={excerpt} >
        { categoryForLocale.length != 0 ? 
        <h2><span title={categoryForLocale}>{categoryCommon}</span></h2>
        : 
        <h2>{categoryCommon}</h2>
        }
        <article>
          { !isFaq ? 
            <div className="date"><img src="/images/paper-plane-solid.svg" alt="News" className="newsIcon" /><Moment>{post.frontmatter.date}</Moment></div>
            :
            null
          }
          <h3 className={ isFaq ? "faq" : "" }><Link to={this.props.path}>{pageTitle}</Link></h3>
          {contentHtml}
          <div className="sharelist"><ShareList path={this.props.path} twitterTitle={`${pageTitle} | ${metadata.title}`} /></div>
        </article>
        { !isFaq ? 
          (previous || next) ? <ul className={pagerClassName}>
          {(previous) ? 
            <li className="nextPost">
              {this.props.intl.formatMessage({ id: 'paging.previous' })}
              <br/>
              <Link to={previous.fields.slug} rel="prev">
                {previous.frontmatter.title[locale]}
              </Link>
            </li>
            :
            null
          }
          {(next) ?
            <li className="previousPost">
              {this.props.intl.formatMessage({ id: 'paging.next' })}
              <br/>
              <Link to={next.fields.slug} rel="prev">
                {next.frontmatter.title[locale]}
              </Link>
            </li>
            :
            null
          }
          </ul> 
          : 
          null
        :
        <p className="bottomLink"><Link to="/category/faq">{this.props.intl.formatMessage({ id: 'faq.backToList' })}</Link></p>
      }
      </LayoutStatic>
    )
  }
}

export default injectIntl(Post)

export const pageQuery = graphql`
query($slug: String!) {
  site {
    siteMetadata {
      postCategories {
        news {
          common
          ja
          en
        }
        faq {
          common
          ja
          en
        }
      }
    }
  }
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    frontmatter {
      title {
        ja
        en
      }
      category
      date
    }
  }
}
`