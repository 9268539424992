import React, { Component } from 'react'

import TwitterButton from './TwitterButton'
import FacebookButton from './FacebookButton'

class ShareList extends Component {
  constructor(props, context) {
    super(props, context)
  }

  render() {
    return (
        <ul className="sharelist">
            <li><TwitterButton pagePath={encodeURI(this.props.path)} shareTitle={this.props.twitterTitle} className="twitter" /></li>
            <li><FacebookButton pagePath={encodeURI(this.props.path)} className="facebook" /></li>
        </ul>
    )
  }
}

export default ShareList
